<template>
  <div>
    <v-toolbar flat class="mb-2">
      <SchoolYearPicker outlined text v-model="schoolYear" />

      <v-spacer />
      <v-text-field
        single-line
        dense
        hide-details
        label="Suche"
        v-model="search"
        append-icon="mdi-magnify"
        clearable
      ></v-text-field>
    </v-toolbar>
    <v-card>
      <v-system-bar>Besuche, Bewertung, Feedback</v-system-bar>
      <v-data-table
        :loading="loading"
        :items="itemsFiltered"
        :headers="headers"
        disable-filtering
        disable-pagination
        hide-default-footer
        must-sort
        sort-by="student"
        @click:row="(el) => showEdit(el)"
        :item-class="() => 'clickable'"
      >
        <template v-slot:item.student="{ item }">
          <PersonItem v-if="item.student" :value="item.student" dense
            ><Rating :value="item.ratingStudent"></Rating>
          </PersonItem>
        </template>
        <template v-slot:item.institution="{ item }">
          <InstitutionItem :value="item.institution" dense
            ><Rating :value="item.ratingInstitution"></Rating
          ></InstitutionItem>
        </template>
        <template v-slot:item.teacher="{ item }">
          <PersonItem :value="item.teacher" dense
            ><Rating :value="item.ratingTeacher"></Rating
          ></PersonItem>
        </template>

        <template v-slot:item.feedback="{ item }">
          <v-tooltip top v-if="item.feedback">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-comment-outline </v-icon>
            </template>
            <span>{{ item.feedback }}</span>
          </v-tooltip></template
        >
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" max-width="700" scrollable>
      <v-card :disabled="saving" :loading="saving">
        <v-system-bar window
          >Besuch bewerten <v-spacer /><v-btn icon @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-system-bar>

        <v-card-text class="pa-0 ma-0 pr-4">
          <v-container>
            <v-row>
              <v-col>
                <PersonItem :value="item.student"> </PersonItem>
              </v-col>
              <v-col>
                <LookupValueInput
                  clearable
                  label="Bewertung"
                  itemText="code"
                  v-model="item.ratingStudent"
                  resource="placement/rating"
                ></LookupValueInput>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <InstitutionItem :value="item.institution"> </InstitutionItem>
              </v-col>
              <v-col>
                <LookupValueInput
                  clearable
                  label="Bewertung"
                  itemText="code"
                  v-model="item.ratingInstitution"
                  resource="placement/rating"
                ></LookupValueInput>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <PersonInput group="teacher" v-model="item.teacher" clearable>
                </PersonInput>
              </v-col>
              <v-col>
                <LookupValueInput
                  clearable
                  label="Bewertung"
                  itemText="code"
                  v-model="item.ratingTeacher"
                  resource="placement/rating"
                ></LookupValueInput>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                ><v-textarea
                  outlined
                  label="feedback"
                  v-model="item.feedback"
                ></v-textarea></v-col
            ></v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="dialog = false"> Abbrechen </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save"> speichern </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InstitutionItem from "./InstitutionItem.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItem from "@/components/PersonItem.vue";
import PersonInput from "common/components/PersonInput.vue";
import Rating from "./Rating.vue";
import SchoolYearPicker from "@/components/SchoolYearPicker.vue";

import { comparePeople, searchPerson } from "common/utils/people.js";

export default {
  components: {
    InstitutionItem,
    LookupValueInput,
    PersonItem,
    PersonInput,
    Rating,
    SchoolYearPicker,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      filterType: null,
      student: null,
      emptyPlacements: [],
      saving: false,
      headers: [
        {
          text: "",
          value: "feedback",
        },
        {
          text: "Schüler*in",
          value: "student",
          sort: comparePeople,
        },
        {
          text: "Institution",
          value: "institution",
          sort: this.compareInstitutions,
        },

        {
          text: "LK",
          value: "teacher",
          sort: comparePeople,
        },
      ],
      items: [],
      item: {},
      schoolYear: null,
      search: "",
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          el.student &&
          el.institution &&
          (!this.search ||
            searchPerson(el.student, this.search) ||
            this.institutionSearch(el, this.search))
      );
    },
  },
  watch: {
    schoolYear() {
      this.fetchData();
    },
  },
  methods: {
    institutionSearch(el, search) {
      return (
        (el.institution &&
          el.institution.instName &&
          el.institution.instName
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.firstName1 &&
          el.institution.firstName1
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.lastName1 &&
          el.institution.lastName1
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.firstName2 &&
          el.institution.firstName2
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.lastName2 &&
          el.institution.lastName2
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.town &&
          el.institution.town.toLowerCase().includes(search.toLowerCase()))
      );
    },

    compareInstitutions(a, b) {
      const strA = `${a.instName ? a.instName : ""}${
        a.lastName1 ? a.lastName1 : ""
      }${a.lastName2 ? a.lastName2 : ""}`;
      const strB = `${b.instName ? b.instName : ""}${
        b.lastName1 ? b.lastName1 : ""
      }${b.lastName2 ? b.lastName2 : ""}`;
      return strA.localeCompare(strB, "de-CH");
    },

    async save() {
      this.saving = true;
      await this.apiPut({
        resource: "placement/placement",
        id: this.item.id,
        data: this.item,
      });
      this.saving = false;
      this.dialog = false;
      this.fetchData();
    },
    showEdit(item) {
      this.item = {
        id: item.id,
        student: item.student,
        institution: item.institution,
        teacher: item.teacher,
        ratingStudent: item.ratingStudent,
        ratingInstitution: item.ratingInstitution,
        ratingTeacher: item.ratingTeacher,
        feedback: item.feedback,
      };
      this.dialog = true;
    },
    async fetchData() {
      this.loading = true;

      this.items = await this.apiList({
        resource: "placement/placement",
        query: "schoolYear=" + this.schoolYear.id,
      });

      this.loading = false;
    },
  },
  created() {},
};
</script>
