<template>
  <v-chip small class="px-1" v-if="value && value.code" outlined>
    <v-icon small v-for="index in count" :key="index">{{ icon }}</v-icon>
  </v-chip>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    count() {
      return this.value && this.value.code ? this.value.code.length : 0;
    },
    icon() {
      if (this.value && this.value.code) {
        if (this.value.code.includes("+")) {
          return "mdi-plus";
        } else if (this.value.code.includes("-")) {
          return "mdi-minus";
        } else {
          return "mdi-circle-outline";
        }
      }
      return "";
    },
  },
};
</script>
